<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section">
    <b-modal v-model="showEditModal" @hidden="handleModalHide" hide-footer size="md">
      <template #modal-title>
        Changement des informations de {{packageDetail.name}}
      </template>
      <b-form @submit.prevent="adminChangeInfo">
        <v-row>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.email" label="Email" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.password" label="Mot de passe" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.name" label="Nom" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.phone" label="Téléphone" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.code" label="Matricule" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
        <label>Type Véhicule</label>
        <b-form-select v-model="packageDetail.delivery_type" required>
            <option v-for="(vehicule,index) in ['Fourgon 400 KG','Fourgon 1.5 TN', 'Camion 2.5 TN', 'Camion 3.5 TN', 'Camion 10 TN', 'Camion 20 TN']" :key="index" :value="vehicule">{{vehicule}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="12">
        <label>Type Service</label>
        <b-form-select v-model="packageDetail.service_type" required>
            <option v-for="(vehicule,index) in ['Service SOS (Auto Dépannage)','Service Logistique', 'Service Express', 'Logistique/Express']" :key="index" :value="vehicule">{{vehicule}}</option>
          </b-form-select>
        </v-col>
        </v-row>
        <v-col cols="12" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Confirmer
          </v-btn>
        </v-col>
        </b-form>
    </b-modal>
  <b-modal v-model="showLoginModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Confirmation
    </template>
    <v-container class="py-0">
      <v-row>
        <v-col cols="12" md="12">
          <b-card title="Voulez vraiment accepter ce chauffeur?">
          </b-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn @click="confirmAction" style="background-color:#4caf50;border-color:#4caf50" class="mr-3">
            Confirmer
          </v-btn>
          <v-btn @click="showLoginModal=false" style="background-color:#dc3545;border-color:#dc3545" class="mr-0">
            Annuler
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </b-modal>
  <b-modal v-model="showDocModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Documents
    </template>
    <v-container class="py-0">
      <v-row>
        <v-col cols="12" md="12">
          <b-card title="Liste des documents renseignés">
            <v-row v-for="file in packageDetail.files" :key="file.file_name">
              <span style="padding:10px"><strong>{{file.doc_name}}:</strong><a target="_blank" style="margin-left:10px" :href="hostName+file.link">{{file.file_name}}</a></span>
            </v-row>
          </b-card>
        </v-col>
      </v-row>
    </v-container>
  </b-modal>
  <b-modal v-model="showPostModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Ajouter un chauffeur
    </template>
    <div style="width:100%;text-align:center"><span style="color:red">{{newPostErrorMessage}}</span></div>
    <v-container class="py-0">
        <b-form @submit.prevent="addNewDriver">
        <v-row>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.email" label="Email" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.password" label="Mot de passe" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.name" label="Nom" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.phone" placeholder="phone1/phone2" label="N° Téléphone" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <label>Wilaya</label>
          <b-form-select required v-model="selectedWilaya" @change="newPost.wilaya = selectedWilaya.name">
            <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <label>Commune</label>
          <b-form-select required v-model="selectedCommunue" @change="newPost.communue = selectedCommunue">
            <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="newPost.address" label="Adresse" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.code" placeholder="XXXXX-XXX-XX" label="Matricule" class="purple-input"/>
        </v-col>
        <!-- <v-col cols="12" md="6">
          <v-text-field required v-model="newPost.home_fees" type="number" label="Frais de livraison domicile" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12" style="text-align:right">
          <v-btn small depressed style="background-color:#4caf50" @click="addFeesException" class="mr-0">Ajouter une exception de frais de transport</v-btn>
        </v-col>
        <v-row v-for="(feesException, index) in feesExceptions" :key="feesException.post_code" :value="feesException.post_code">
          <v-col cols="12" md="6">
          <label>Poste</label>
          <b-form-select style="margin-top:15px" v-model="feesException.post_code">
            <option v-for="post in rows" :key="post.code" :value="post.code">{{post.name}}->{{post.wilaya}}</option>
          </b-form-select>
          </v-col>
          <v-col cols="12" md="5">
            <label>Frais</label>
            <v-text-field v-model="feesException.fees" type="number" label="Frais de livraison entre postes" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="1">
            <b-button-close style="margin-top:20px" @click="removeFeesExeception(index)"/>
          </v-col> -->
        </v-row>
        <v-col cols="12" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Ajouter
          </v-btn>
        </v-col>
        </b-form>
    </v-container>
  </b-modal>
  <base-material-card icon="mdi-package-variant-closed" title="Liste des chaffeurs" class="px-5 py-3">
  <template v-slot:heading>
    <v-icon @click="reloadItems">mdi-reload</v-icon>
  </template>
   <div style="width:100%;text-align:right">
     <v-btn color="success" small depressed @click="showPostModal = true">Ajouter un chauffeur</v-btn>
   </div>
   <!-- @on-row-click="rowClick" -->
    <vue-good-table
      max-height="700px"
      :columns="columns"
      :isLoading.sync="isLoading"
      :rows="rows"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
      style-class="vgt-table condensed bordered tableOne"
    >
    <template slot="loadingContent">
        <div class="spinner spinner-primary mx-auto my-auto"></div>
    </template>
    <!-- <template slot="column-filter" slot-scope="props">
      <b-form-datepicker v-if="props.column.filterOptions.customFilterDate" @input="handleCustomFilterDate"></b-form-datepicker>
    </template> -->
    <template slot="table-row" slot-scope="props">
        <span style="color:red;" v-if="props.column.field == 'incoming_packages'">
          {{props.row[props.column.field]}}
        </span>
        <span  v-else-if="props.column.field == 'name'">
          <v-icon @click="showEdit(props.row)" style="color:blue;margin-left:10px;float:right">mdi-square-edit-outline</v-icon>
          {{props.row[props.column.field]}}
          <v-icon v-if="props.row.dlv_type == 'homedlv'" style="float:right;" color='green'>mdi-home</v-icon>
          <v-icon v-if="props.row.dlv_type == 'stopdesk'" style="float:right;" color='green'>mdi-bank-outline</v-icon>
        </span>
        <span  v-else-if="props.column.field == 'action'">
          <!-- <v-icon id="popover-reactive-1" @click="showConfirmation(props.row)" style="color:#dc3545;margin-left:10px;">mdi-close-circle</v-icon> -->
          <!-- <b-popover
            target="popover-reactive-1"
            triggers=""
            :show.sync="popoverShow"
            placement="auto"
            container="my-container"
            ref="popover"
          >
            <template #title>
              CONFIRMATION
            </template>
            <b-button @click="onClose" size="sm" style="margin-right:10px" variant="danger">Cancel</b-button>
            <b-button @click="deleteDriver" size="sm" variant="primary">Ok</b-button>
          </b-popover> -->
          <span v-if="props.row.approved==null">
            <span style="color:#ffa700">{{props.row.approved_value}}</span>
            <v-icon id="action-btn" @click="prepareAction(props.row,'refuse')" style="color:#dc3545;margin-left:10px;">mdi-close-circle</v-icon>
            <v-icon id="action-btn" @click="prepareAction(props.row,'accept')" style="color:#4caf50;margin-left:10px;">mdi-check-bold</v-icon>
          </span>
          <span v-if="props.row.approved==true">
            <span style="color:green">{{props.row.approved_value}}</span>
            <v-icon id="action-btn" @click="prepareAction(props.row,'refuse')" style="color:#dc3545;margin-left:10px;">mdi-close-circle</v-icon>
          </span>
          <span v-if="props.row.approved==false">
            <span style="color:red">{{props.row.approved_value}}</span>
            <v-icon id="action-btn" @click="prepareAction(props.row,'accept')" style="color:#4caf50;margin-left:10px;">mdi-check-bold</v-icon>
          </span>
          <v-icon @click="showDocModal=true;packageDetail=props.row" style="color:blue;margin-left:10px;">mdi-file-document</v-icon>
          <v-icon id="goto-provider" @click="goToPost(props.row)" style="color:blue;margin-left:10px;">mdi-open-in-new</v-icon>
        </span>
        <span style="color:#4caf50;" v-else-if="props.column.field == 'outgoing_packages'">
          {{props.row[props.column.field]}}
        </span>
        <span v-else-if="props.column.field == 'status'">
          <v-icon v-if="props.row.status == 'online'" color='green'>mdi-circle</v-icon>
          <v-icon v-if="props.row.status == 'offline'" color='red'>mdi-circle</v-icon>
          <v-icon v-if="props.row.status == 'indelivery'" color='yellow'>mdi-circle</v-icon>
        </span>
        <span v-else-if="props.column.field == 'password'">
        <b-button @click="copyPassword(props.row)" v-b-tooltip.hover :title="copyMessage" id="password-btn" style="background-color:#4caf50;color:white;" >
          <v-badge v-if="props.row.is_new_post" color="red" overlap bordered>
            <template v-slot:badge>
              <span>new</span>
            </template>
            {{props.row.password}}
          </v-badge>
          <span v-else>{{props.row.password}}</span>
        </b-button>
        </span>
        <span v-else>
          {{props.row[props.column.field]}}
        </span>
    </template>
    </vue-good-table>
  </base-material-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
export default {
  name: 'CenterDashboard',
  data () {
    return {
      copyMessage: 'Cliquer pour copier',
      newPostErrorMessage: null,
      wilayas: [],
      communues: [],
      selectedWilaya: {
        name:null,
        communues: []
      },
      statusList: ['En attente', 'Accepté', 'Réfusé'],
      selectedCommunue: null,
      showPackageModal: false,
      showHisotryModal: false,
      isLoading: false,
      totalRows: 0,
      tableFilters: {
        approved: ''
      },
      outgoingTableFilters: {
        id: null,
        name: null,
        status: null,
        from_wilaya: null,
        date: null
      },
      history: {
        wilaya: null,
        communue: null,
        tracking_id: null,
        show: false,
        message: null,
        creation_date: {
          date: null,
          time: null
        }
      },
      packageDetail: {
        home_fees:0,
      },
      columns:[
        {
          label: '',
          field: 'status',
          width: '20px',
        },
        {
          label: 'Nom',
          field: 'name',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Téléphone',
          field: 'phone',
          width: '100px',
          filterOptions: {
            enabled:true,
            placeholder: 'Enter le numéro',
            // trigger: 'enter',
          }
        },
        // {
        //   label: 'Email',
        //   field: 'email',
        //   width: '100px',
        //   filterOptions: {
        //     enabled:true,
        //     placeholder: 'Enter le numéro',
        //     // trigger: 'enter',
        //   }
        // },
        // {
        //   label: 'Mot de passe',
        //   field: 'password',
        //   width: '140px',
        //   filterOptions: {
        //     enabled:true,
        //     placeholder: 'Enter le numéro',
        //     // trigger: 'enter',
        //   }
        // },
        {
          label: 'Type de véhicule',
          field: 'delivery_type',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Choisir la wilaya',
            // trigger: 'enter',
          },
        },
        {
          label: 'Type de service',
          field: 'service_type',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Choisir la wilaya',
            // trigger: 'enter',
          },
        },
        {
          label: 'Wilaya',
          field: 'wilaya',
          width: '100px',
          filterOptions: {
            enabled: true,
            placeholder: 'Choisir la wilaya',
            // trigger: 'enter',
          },
        },
        {
          label: 'Commune',
          field: 'communue',
          width: '100px',
          filterOptions: {
            enabled: false,
            placeholder: 'Choisir la commune',
            // trigger: 'enter',
          },
        },
        {
          label: 'Matricule',
          field: 'code',
          width: '150px',
          filterOptions: {
            enabled: false,
            placeholder: 'Choisir la commune',
            // trigger: 'enter',
          },
        },
        {
          label: 'Action',
          field: 'action',
          width: '80px',
        },
        // {
        //   label: 'Entrants',
        //   field: 'incoming_packages',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Sortants',
        //   field: 'outgoing_packages',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Delivered',
        //   field: 'total_delivered',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
        // {
        //   label: 'Returned',
        //   field: 'total_returned',
        //   width: '100px',
        //   type:'number',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Choisir la commune',
        //     // trigger: 'enter',
        //   },
        // },
      ],
      newPost: {
        email:null,
        password:null,
        wilaya:null,
        communue:null,
        phone:null,
        address:null,
        name: null,
        home_fees: null,
        code: null
      },
      rows: [],
      outgoingRows: [],
      showLoginModal: false,
      showDocModal:false,
      showPostModal: false,
      preparedRow: null,
      preparedAction: null,
      feesExceptions: [],
      popoverShow : false,
      selectedRow:null,
      showEditModal:false,
      hostName: null,
    }
  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.hostName = process.env.VUE_APP_MEDIA_URL.replace('/media/','')
    this.getWilayas()
    this.loadDrivers()
    // this.columns[4].filterOptions.filterDropdownItems = this.statusList
    this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.statusList);
  },
  methods: {
    adminChangeInfo() {
      axios.post('admin-change-profile', {
        auth_token: localStorage.getItem('auth_token'),
        user_info: {
          auth_token: this.packageDetail.auth_token,
          email:this.packageDetail.email,
          password:this.packageDetail.password,
          name: this.packageDetail.name,
          phone: this.packageDetail.phone,
          code: this.packageDetail.code,
          service_type: this.packageDetail.service_type,
          selectedVehicule: this.packageDetail.delivery_type,
          user_type: 'driver'
        }
      }).then(response => {
        this.showEditModal = false
        this.loadDrivers()
      })
    },
    showEdit(row) {
      this.packageDetail = row
      this.showEditModal = true
    },
    copyPassword(row) {
      this.$clipboard(row.password);
      this.copyMessage = 'Copié!'
      setTimeout(() => {
        this.copyMessage = 'Cliquer pour copier'
      }, 2000);
    },
    reloadItems() {
      this.isLoading = true
      this.loadDrivers()
    },
    showConfirmation(row) {
      this.popoverShow = true
      this.selectedRow = row
    },
    deleteDriver() {
      axios.post('delete-driver',{
        auth_token: localStorage.getItem('auth_token'),
        code: this.selectedRow.code,
        did: this.selectedRow.did
      }).then(response =>{
        this.loadDrivers()
      })
    },
    onClose(){
      this.popoverShow = false
    },
    delete_post(){
      axios.post('delete-post',{auth_token: localStorage.getItem('auth_token'),
      code: this.packageDetail.code}).then(response =>{
        this.popoverShow = false
        this.showLoginModal = false
        this.loadDrivers()
      })
    },
    getWilayas() {
      axios.post('get-wilayas').then(response => {
        if (response.data.status == 'success') {
          this.wilayas = response.data.data
          let wilayas = []
          this.wilayas.forEach(element => {
            wilayas.push(element.name)
          });
          this.$set(this.columns[2].filterOptions, 'filterDropdownItems', wilayas);
          // this.columns[2].filterOptions.filterDropdownItems = wilayas
        }
      })
    },
    prepareAction(row,action) {
      this.preparedRow = row
      this.preparedAction = action
      this.showLoginModal = true
      this.packageDetail = row
    },
    confirmAction() {
      if (this.preparedAction == 'accept') this.acceptProvider(this.preparedRow)
      else if (this.preparedAction == 'refuse') this.refuseProvider(this.preparedRow)
      this.showLoginModal = false
      this.loadDrivers()
    },
    handleModalHide(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    hideModal(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showPostModal = false
      this.history.show = false
      this.newPostErrorMessage = null
    },
    loadDrivers() {
      axios.post('admin-drivers', {
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        if (response.data.status == 'success')
          this.rows = response.data.data
      })
    },
    goToPost(packageDetail) {
      localStorage.setItem('admin_auth_token',localStorage.getItem('auth_token'))
      localStorage.setItem('auth_token',packageDetail.auth_token)
      window.location.replace("driver-dashboard");
    },
    rowClick(params) {
      // if (!this.showOrderWithDuplicates && params.event.target.localName !== "input" && params.event.target.localName !== "select" && params.event.target.localName !== "option" && params.event.target.className !== "vgt-checkbox-col" && params.event.target.className !== "dropdown-item" && params.event.target.type !== "button" && params.event.target.tagName !== "A") {
      this.packageDetail = params.row
      if (params.event.target.id == "status-btn") {
      }
      else if (params.event.target.id == 'goto-provider') {}
      else if (params.event.target.id == "history-btn") {
        this.showHisotryModal = true
        this.history.tracking_id = params.row.tracking_id
      } else {
        this.showLoginModal = true
        this.feesExceptions = []
      }
    },
    changeStatus(row, tableType) {
      axios.post('center-change-status', {
        auth_token: localStorage.getItem('auth_token'),
        tracking_id: row.tracking_id,
        new_status: row.status
      }).then(response => {
        if (response.data.status == 'success') {
          if (tableType == 'incoming') this.loadIncomingItems()
          else if (tableType == 'outgoing') this.loadOutgoingItems()
        }
      })
    },
    onColumnFilter(params) {
      console.log(params)
      this.tableFilters = params.columnFilters
      if (params.columnFilters.approved_value == this.statusList[0]) this.tableFilters.approved = null
      else if (params.columnFilters.approved_value == this.statusList[1]) this.tableFilters.approved = true
      else if (params.columnFilters.approved_value == this.statusList[2]) this.tableFilters.approved = false
      else this.tableFilters.approved = ''
      this.loadDrivers()
    },
    onSortChange(params) {
      let t = params[0].type
      let f = params[0].field
      this.rows.sort(function(a, b) {
        if (t == 'asc') {
          if ( a[f] < b[f] ){
            return -1;
          }
          if ( a[f] > b[f] ){
            return 1;
          }
        }
        else if (t == 'desc') {
          if ( a[f] < b[f] ){
            return 1;
          }
          if ( a[f] > b[f] ){
            return -1;
          }
        }
      });
    },
    onPageChange(params) {
      console.log(params)
    },
    onPerPageChange(params) {
      console.log(params)
    },
    // this.$set(this.columns[5].filterOptions, 'filterValue', '');
    refuseProvider(row) {
      axios.post('admin-refuse-driver', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = false
        }
      })
    },
    acceptProvider(row) {
      axios.post('admin-accept-driver', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = true
        }
      })
    },
    addFeesException() {
      this.feesExceptions.push({
        post_code: null,
        fees: null
      })
    },
    removeFeesExeception(index) {
      this.feesExceptions.splice(index,1)
    },
    changeFees() {
      axios.post('admin-change-post-fees', {
        auth_token: localStorage.getItem('auth_token'),
        code: this.packageDetail.code,
        home_fees: this.packageDetail.home_fees,
        fees_exceptions: this.feesExceptions,
        dlv_type: this.packageDetail.dlv_type,
      }).then(response => {
        if (response.data.status == 'success') {
          this.showLoginModal = false
          this.loadDrivers()
        }
      })
    },
    addNewDriver() {
      axios.post('admin-add-driver', {
        auth_token: localStorage.getItem('auth_token'),
        post_info: this.newPost,
        fees_exceptions: this.feesExceptions
      }).then(response => {
        if (response.data.status == 'success') {
          this.loadDrivers()
          this.showPostModal = false
          this.newPost= {
            email:null,
            password:null,
            wilaya:null,
            communue:null,
            phone:null,
            address:null,
            name: null,
            home_fees: null,
            code: null
          }
          this.feesExceptions = []
          this.newPostErrorMessage = null
        } else {
          this.newPostErrorMessage = response.data.error_message
        }
      })
    },
  },
}
</script>
<style>
.vgt-responsive {
  min-height: 450px;
}
</style>
